import { template as template_8936be6e1ff640089a720c425a80ea72 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_8936be6e1ff640089a720c425a80ea72(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
