import { template as template_c37df0678e7a4959b8c00be8d658b22c } from "@ember/template-compiler";
const FKLabel = template_c37df0678e7a4959b8c00be8d658b22c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
